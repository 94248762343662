<template>
  <div class="type-return-coupon">
    <AddOnItem
      :language="language"
      :abt-info="abtInfo"
      :drawer-config="drawerConfig"
      :query-info="{
        ...$attrs.queryInfo,
        ...anotherInfo,
      }"
      :type="$attrs.type"
      :sa-info="$attrs.saInfo"
      :config="config"
      :store-info="$attrs.storeInfo"
      :footer-type="footerType"
      :feeds-type="feedsType"
      :get-business-cart-foot="getBusinessCartFoot"
    >
      <template slot="header">
        <transition 
          name="header-animate"
        >
          <component
            :is="headerName"
            v-if="!isFullScreen"
            :language="language"
            :promotion="promotion"
          />
          <FullScreenHead
            v-else
            :type=" headerName === 'NewUserHead' ? 'newUserReturnCoupon' : 'returnCoupon'"
            :tips="headerTitle"
          />
        </transition>
      </template>
      <template #footer="{ viewCart }">
        <BusinessCartFoot 
          v-if="isBusinessCartBottom"
          ref="BusinessCartFoot"
          type="promotion"
          scene="promotion"
          :promotion-id="promotion.promotion_id"
          :state="$attrs.saInfo && $attrs.saInfo.state"
          :tips="promotion.tip"
          :status="addOnStatus"
          :abt-type="abtType"
          :feeds-type="feedsType"
          :auto-use-coupon="autoUseCoupon"
          :auto-use-point="autoUsePoint"
          theme="promotion"
          :theme-vars="themeVars"
          @checkoutClick="viewCart"
          @cartUpdated="hanldeBusinessCartUpdated"
        >
          <template #progress>
            <ProgressBar
              transparent
              animation
            />
          </template>
        </BusinessCartFoot>
        <NormalFoot
          v-else
          :language="language"
          :desc="promotion.tip"
          @back="viewCart"
        />
      </template>
    </AddOnItem>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { EventProgressUpdate, EventUpdateBusinessCart, EventSetProgressPromise } from '../../utils/event.js'
import { template } from '@shein/common-function'
import { calcPromotionProcess, getPromotionProgressStatus } from 'public/src/pages/common/addOnItem/utils/index.js'
import { isNewUserReturnCoupon } from '@/public/src/pages/cartNew/utils/index'
import BusinessCartFoot from 'public/src/pages/common/add-on/comps/business-cart-foot/index.vue'
const { IS_RW } = gbCommonInfo

export default {
  name: 'TypeReturnCoupon',
  components: {
    AddOnItem: () => import(/* webpackChunkName: "add_on_item" */'../AddOnItem.vue'),
    NormalHead: () => import(/* webpackChunkName: "add_on_item" */'./normal/Head.vue'),
    NormalFoot: () => import(/* webpackChunkName: "add_on_item" */'./normal/Foot.vue'),
    FullScreenHead: () => import(/* webpackChunkName: "add_on_item" */'public/src/pages/common/addOnItem/comps/base/FullScreenHead.vue'),
    NewUserHead: () => import(/* webpackChunkName: "add_on_item" */'./new-user/Head.vue'),
    BusinessCartFoot,
    ProgressBar: () => import(/* webpackChunkName: "add_on_item" */'public/src/pages/common/addOnItem/comps/base/ProgressBar.vue'),
  },
  props: {
    promotion: {
      type: Object,
      default: () => ({}),
    },
    drawerConfig: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isShowAnimation4: false, // 展示过动效 4
      isShowAnimation5: false, // 展示过动效 5
    }
  },
  computed: {
    ...mapState(['abtInfo', 'language', 'progressDataPromise', 'isFullScreen']),
    anotherInfo() {
      const { diffAmount, lable_id } = this.promotion
      const jsonRuleId = this.abtInfo?.addnewhotJson?.param || {}
      return {
        goodsPrice: ((diffAmount?.usdAmount || 0) * 100).toFixed(0), // 凑单差额价格(美分)
        includeTspId: lable_id,
        jsonRuleId,
        location: 'addnewhotJson',
      }
    },
    isNewUser() {
      return this.abtInfo?.orderCouponNewUser?.param?.orderCoupon_newUser === 'new' && isNewUserReturnCoupon(this.promotion)
    },
    headerTitle() {
      return this.promotion.couponNum > 1 ? this.language.SHEIN_KEY_PWA_27303 : this.language.SHEIN_KEY_PWA_27302
    },
    headerName() {
      if (this.isNewUser) {
        return 'NewUserHead'
      } 
      return 'NormalHead'
    },
    // 非标车
    themeVars() {
      return this.isNewUser ? {
        '--cart-title-color': '#FFFDD8',
        '--cart-close-color': '#fff', // 关闭按钮颜色
        '--cart-delete-title-color': '#fff', // 删除挽留弹窗主标题颜色
        '--cart-delete-close-color': '#fff', // 删除挽留弹窗关闭 "x", icon 颜色
        '--cart-title-doubt-color': '#fff', // 标题问号颜色
        '--cart-promo-header-bg': 'linear-gradient(183deg, #FFD0D0 2.79%, #FFF 59.78%), linear-gradient(183deg, #FFF4CD 2.79%, #FFF 59.78%)', // 活动分组背景
      } : {}
    },
    footerType() {
      if (this.isBusinessCartBottom) return 'business-cart'
      return 'normal'
    },
    abtType() {
      const { cartadd_promotion_bottom_style } = this.abtInfo?.CartaddPromotionBottomStyle?.param || {}
      return cartadd_promotion_bottom_style || 'A'
    },
    feedsType() {
      const { cartpop_feeds_additems } = this.abtInfo?.CartaddFeeds?.param || {}
      return cartpop_feeds_additems || 'off' // off | on_A | on_B
    },
    isBusinessCartBottom() {
      if (IS_RW) return false
      return ['A', 'B', 'C'].includes(this.abtType) && !this.config.isCloseBusinessCartEntry
    },
    addOnStatus() {
      return this.promotion?.processCount > 100 ? 1 : 0
    },
    autoUseCoupon() {
      return this.abtInfo?.Cartshowcoupon?.param?.cart_show_coupon_switch == 'on' ? '1' : '0'
    },
    autoUsePoint() {
      const [auto_use_point] = (this.abtInfo?.cartautopoints?.param?.is_auto_point || '').split('_')
      return auto_use_point == 0 ? '0' : '1'
    }
  },
  watch: {
    'promotion': {
      handler(newV, oldV) {
        const newProgressData = this.getProgressData(newV)
        const oldProgressData = this.getProgressData(oldV)
        const { isChange, isUp } = getPromotionProgressStatus(newProgressData, oldProgressData)
        if (isChange) {
          this.$refs.BusinessCartFoot?.refetchCarts()
          this.progressDataPromise?.resolve?.({
            progressData: this.getProgressData(newV),
            bubbleText: isUp ? this.getBubbleText(oldV) : '', // 进度提升才去判断气泡提示
          })
        }
      },
      deep: true,
    }
  },
  mounted() {
    EventProgressUpdate.notifySync(this.getProgressData(this.promotion))
  },
  methods: {
    getProgressData(promo) {
      const { promotionRangeInfo, rules } = promo
      const { range, next } = promotionRangeInfo || {}
      if (!Array.isArray(rules)) return []
      return rules.map((rule, index) => {
        return {
          progressRate: calcPromotionProcess(rule, rules[index - 1], index, promotionRangeInfo), // 凑单进度
          ruleIndex: index, // 当前档位
          activeRuleIndex: next == 0 ? -1 : range, // 正在凑单档位
          range,
        }
      })
    },
    getBubbleText(oldProcess) {
      // 上一次凑单进度已经到100了, 就不提示了
      if (oldProcess >= 100) {
        return ''
      }
      let text = ''
      // 返券 当前只有 1 档
      if (this.promotion.range === 0) {
        // 动效 4 只展示一次，展示过动效 5 不展示动效 4
        if (this.isShowAnimation4 || this.isShowAnimation5) return text
        this.isShowAnimation4 = true
        text = template(this.promotion?.diffAmount?.amountWithSymbol || 0, this.language.SHEIN_KEY_PWA_27314)
      } else if (this.promotion.range === 1) {
        // 动效 5 只展示一次
        if (this.isShowAnimation5) return text
        this.isShowAnimation5 = true
        text = this.language.SHEIN_KEY_PWA_27315
      }
      return text
    },
    getSaExtraData() {
      const { processCount, promotion_id } = this.promotion
      return {
        promotion_code: promotion_id,
        is_satisfied: processCount >= 100 ? 1 : 0,
        coupon_change: processCount >= 100 ? 1 : 0,
        is_couponbag: this.promotion.couponNum > 1 ? 1 : 0,
        newuser_label: isNewUserReturnCoupon(this.promotion) ? 1 : 0,
      }
    },
    hanldeBusinessCartUpdated(info) {
      if (['updateQuantity', 'delete', 'modify', 'updateAttr'].includes(info?.action)) {
        EventSetProgressPromise.notify()
      }
      EventUpdateBusinessCart.notify(info) // 通知外层调用方
    },
    getBusinessCartFoot() {
      return this.$refs.BusinessCartFoot
    },
  },
}
</script>

<style lang="less" scoped>
.type-return-coupon {
  height: 100%;
  .header-animate-enter-active,.header-animate-leave-active { /* stylelint-disable-line */
    transition: all .2s;
  }
  .header-animate-enter,
  .header-animate-leave-to { /* stylelint-disable-line */
    height: 0;
    opacity: .6;
  }
}
</style>

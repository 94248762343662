<template>
  <div class="accessory">
    <AddOnItem
      :language="language"
      :abt-info="abtInfo"
      :drawer-config="drawerConfig"
      :query-info="{
        ...$attrs.queryInfo,
        ...anotherInfo,
      }"
      :type="$attrs.type"
      :sa-info="$attrs.saInfo"
      :config="config"
      :footer-type="footerType"
      :feeds-type="feedsType"
      :get-business-cart-foot="getBusinessCartFoot"
      @addBagAnimateFinish="addBagAnimateFinish"
    >
      <template #header>
        <AccessoryHead
          :fullScreen="isFullScreen"
          :language="language"
          :showRule="showRule"
          :title="headTitle"
          :tips="headTips"
          :icon-src="iconSrc"
          :overFirstRange="overFirstRange"
          :timeStamp="timeStamp"
          :rangeTextList="rangeTextList"
          :showCountDown="showCountDown"
          :headStyleInfo="headStyleInfo"
          @pick="handlePick"
          @close="handleClose"
        />
      </template>
      <template #footer>
        <BusinessCartFoot 
          v-if="isBusinessCartBottom"
          ref="BusinessCartFoot"
          type="promotion"
          scene="promotion"
          :promotion-id="promotion.promotion_id"
          :state="$attrs.saInfo && $attrs.saInfo.state"
          :tips="promotion.cartTips"
          :status="addOnStatus"
          :abt-type="abtType"
          :feeds-type="feedsType"
          :auto-use-coupon="autoUseCoupon"
          :auto-use-point="autoUsePoint"
          @checkoutClick="viewCart"
          @cartUpdated="hanldeBusinessCartUpdated"
          @re-pick="handlePick"
        >
          <template #progress>
            <ProgressBar
              transparent
              animation
            />
          </template>
        </BusinessCartFoot>
        <Foot
          v-else  
          :language="language"
          :promotion="promotion"
          @back="viewCart"
        />
      </template>
    </AddOnItem>
    <AttentionDrawer
      :visible="attentionConfig.show"
      :tips="attentionConfig.tips"
      :confirmText="attentionConfig.confirmText"
      :canPickProducts="canPickProducts"
      :language="language"
      @close="handleAttentionDrawerClose"
      @giveUp="handleGiveUp"
      @pick="handlePick"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { EventProgressUpdate, EventClose, EventPickItem, EventUpdateBusinessCart, EventSetProgressPromise } from '../../utils/event.js'
import { calcPromotionProcess, getPromotionProgressStatus } from 'public/src/pages/common/addOnItem/utils/index.js'
import { template } from '@shein/common-function'
import BusinessCartFoot from 'public/src/pages/common/add-on/comps/business-cart-foot/index.vue'

const { IS_RW } = gbCommonInfo

const isSoldOut = (item) => {
  return [2, 3].includes(+item.on_sale_status) || item.stock_status == '2'
}
const isFreeGift = (typeId) => {
  return [4, 2, 28].includes(+typeId)
}
const isSheinClubGift = (promotion) => {
  const { type_id, promotion_logo_type } = promotion || {}
  return type_id == 4 && [8, 14].includes(promotion_logo_type)
}
const getDiffValue = (rule) => {
  return rule?.value_amount?.amountWithSymbol || rule?.value
}

export default {
  name: 'TypeAccessory',
  components: {
    AddOnItem: () => import(/* webpackChunkName: "add_on_item" */'../AddOnItem.vue'),
    Foot: () => import(/* webpackChunkName: "add_on_item" */'./Foot.vue'),
    AccessoryHead: () => import(/* webpackChunkName: "add_on_item" */'./Head.vue'),
    AttentionDrawer: () => import(/* webpackChunkName: "add_on_item" */'./AttentionDrawer.vue'),
    BusinessCartFoot,
    ProgressBar: () => import(/* webpackChunkName: "add_on_item" */'public/src/pages/common/addOnItem/comps/base/ProgressBar.vue'),
  },
  props: {
    promotion: {
      type: Object,
      default: () => ({}),
    },
    drawerConfig: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      attentionConfig: {
        show: false,
        tips: '',
        confirmText: '',
      },
      couponChange: 0,

      needAutoPick: false,
      isShowAutoPick: false,
      showBubble5: false,
      showBubble4: false,
    }
  },
  computed: {
    ...mapState(['abtInfo', 'language', 'isFullScreen', 'progressDataPromise']),
    anotherInfo() {
      const { promotion_range_info, lable_id } = this.promotion
      const jsonRuleId = this.abtInfo?.addnewhotJson?.param || {}
      return {
        goodsPrice: ((promotion_range_info?.diff?.value_amount?.usdAmount || 0) * 100).toFixed(0), // 凑单差额价格(美分)
        includeTspId: lable_id,
        jsonRuleId,
        location: 'addnewhotJson',
      }
    },
    showRule() {
      const { promotion_range_info, rules } = this.promotion
      const { range } = promotion_range_info || {}
      const r = rules[range == 0 ? range : range - 1]
      return {
        ...r,
        index: range == 0 ? range : range - 1,
        list: r?.list?.filter(item => !isSoldOut(item))
      }
    },
    canPickProducts() {
      const { promotion_range_info, rules } = this.promotion
      const { range } = promotion_range_info
      if (this.promotion?.isPicked || range == 0) return []
      return  rules?.slice(0, range).reverse().reduce((acc, cur) => {
        const list = cur?.list?.filter(item => !isSoldOut(item))
        return acc.concat(list)
      }, [])
    },
    iconSrc() {
      return isFreeGift(this.promotion?.type_id) ? 'add-on/gift-14f47da230.png' : 'add-on/add-buy-e6d10a2f24.png'
    },
    headTitle() {
      if (isSheinClubGift(this.promotion)) {
        return this.language.SHEIN_KEY_PWA_33766
      }
      if (isFreeGift(this.promotion?.type_id)) {
        return this.language.SHEIN_KEY_PWA_27785
      }
      return this.language.SHEIN_KEY_PWA_27786
    },
    headTips() {
      if (isFreeGift(this.promotion?.type_id)) {
        return this.overFirstRange ? this.language.SHEIN_KEY_PWA_27789 : this.language.SHEIN_KEY_PWA_27788
      }
      return this.overFirstRange ? this.language.SHEIN_KEY_PWA_27791 : this.language.SHEIN_KEY_PWA_27790
    },
    overFirstRange() {
      return this.promotion?.promotion_range_info?.range != 0
    },
    showCountDown() {
      return !this.isFullScreen && !!this.promotion.is_count_down
    },
    timeStamp() {
      return (this.promotion?.end_time || 0) - Date.now() / 1000
    },
    rangeTextList() {
      const { rules } = this.promotion
      if (!Array.isArray(rules)) return []
      return rules.map((item) => template(`${getDiffValue(item)}`), this.language.SHEIN_KEY_PWA_27787)
    },
    // 非标车
    abtType() {
      const { cartadd_promotion_bottom_style } = this.abtInfo?.CartaddPromotionBottomStyle?.param || {}
      return cartadd_promotion_bottom_style || 'A'
    },
    feedsType() {
      const { cartpop_feeds_additems } = this.abtInfo?.CartaddFeeds?.param || {}
      return cartpop_feeds_additems || 'off' // off | on_A | on_B
    },
    isBusinessCartBottom() {
      if (IS_RW) return false
      return ['A', 'B', 'C'].includes(this.abtType) && !this.config.isCloseBusinessCartEntry
    },
    footerType() {
      if (this.isBusinessCartBottom) return 'business-cart'
      return 'normal'
    },
    addOnStatus() {
      const { range, next } = this.promotion?.promotion_range_info || {}
      return next == 0 ? 1 : (range > 0 ? 2 : 0)
    },
    autoUseCoupon() {
      return this.abtInfo?.Cartshowcoupon?.param?.cart_show_coupon_switch == 'on' ? '1' : '0'
    },
    autoUsePoint() {
      const [auto_use_point] = (this.abtInfo?.cartautopoints?.param?.is_auto_point || '').split('_')
      return auto_use_point == 0 ? '0' : '1'
    },
    headStyleInfo() {
      if (isSheinClubGift(this.promotion)) {
        return {
          '--head-bg': 'url(http://img.ltwebstatic.com/images3_acp/2024/09/11/63/1726035231e73b70f5152a2f6b0bff96d75c3ca0eb.png)',
        }
      }

      return {}
    },
  },
  watch: {
    'promotion': {
      handler(newV, oldV) {
        // 自动唤起附属品弹窗
        if (newV?.promotion_range_info?.range > 0 && oldV?.promotion_range_info?.range == 0) {
          this.needAutoPick = true
        }

        if (newV?.promotion_range_info?.range > oldV?.promotion_range_info?.range) {
          this.couponChange = 1
        }

        const newProgressData = this.getProgressData(newV)
        const oldProgressData = this.getProgressData(oldV)
        const { isChange, isUp } = getPromotionProgressStatus(newProgressData, oldProgressData)

        if (isChange) {
          this.$refs.BusinessCartFoot?.refetchCarts()
          this.progressDataPromise?.resolve?.({
            progressData: this.getProgressData(newV),
            bubbleText: isUp ? this.getBubbleText(newV, oldV) : '',
          })
        }
      },
      deep: true,
    }
  },
  mounted() {
    EventProgressUpdate.notifySync(this.getProgressData(this.promotion))
  },
  methods: {
    getProgressData(promo) {
      const { promotion_range_info, rules } = promo
      const { range, next } = promotion_range_info || {}
      if (!Array.isArray(rules)) return []
      return rules.map((rule, index) => {
        return {
          progressRate: calcPromotionProcess(rule, rules[index - 1], index, promotion_range_info), // 凑单进度
          ruleIndex: index, // 当前档位
          activeRuleIndex: next == 0 ? -1 : range, // 正在凑单档位
          range,
        }
      })
    },
    getBubbleText(newV, oldV) {
      // 旧的已经是最高档不需要提示
      if (oldV?.promotion_range_info?.next == 0) return ''
      
      // 升了档位
      if (newV?.promotion_range_info?.range > oldV?.promotion_range_info?.range) {
        if (this.showBubble5) return ''
        this.showBubble5 = true
        return oldV?.promotion_range_info?.range >= 1 ?
          (isFreeGift(this.promotion?.type_id) ? this.language.SHEIN_KEY_PWA_27802 : this.language.SHEIN_KEY_PWA_27803) :
          (isFreeGift(this.promotion?.type_id) ? this.language.SHEIN_KEY_PWA_27800 : this.language.SHEIN_KEY_PWA_27801)
      }
      const diffValue = getDiffValue(newV?.promotion_range_info?.diff)
      // 没升档位
      if (this.showBubble5 || this.showBubble4) return ''
      this.showBubble4 = true
      return newV?.promotion_range_info?.range >= 1 ?
        (isFreeGift(this.promotion?.type_id) ? template(diffValue, this.language.SHEIN_KEY_PWA_27798) : template(diffValue, this.language.SHEIN_KEY_PWA_27799)) :
        (isFreeGift(this.promotion?.type_id) ? template(diffValue, this.language.SHEIN_KEY_PWA_27796) : template(diffValue, this.language.SHEIN_KEY_PWA_27797))
    },
    handleClose() {
      if (this.canPickProducts?.length) {
        this.handleAttentionDrawerOpen()
        return
      }
      EventClose.notify()
    },
    handlePick(isAuto) {
      this.handleAttentionDrawerClose()
      
      let status = ''
      const { promotion_id, type_id, promotion_logo_type } = this.promotion
      if ([4, 28].includes(+type_id)) {
        status = promotion_logo_type === 8 ? 'clubGiftShow' : 'fullGiftShow'
      }
      if ([2].includes(+type_id)) {
        status = 'buyGiftShow'
      }
      if ([22, 13].includes(+type_id)) {
        status = 'addBuyShow'
      }
      EventPickItem.notify({ 
        appendageInfo: {
          status,
          promotionId: promotion_id,
          fromType: isAuto ? 'addItem-auto' : 'addItem'
        }
      })
    },
    viewCart() {
      if (this.canPickProducts?.length) {
        this.handleAttentionDrawerOpen()
        return
      }
      EventClose.notify()
    },
    handleAttentionDrawerOpen() {
      this.attentionConfig.show = true
      this.attentionConfig.tips = isFreeGift(this.promotion?.type_id) ? this.language.SHEIN_KEY_PWA_27805 : this.language.SHEIN_KEY_PWA_27804
      this.attentionConfig.confirmText = isFreeGift(this.promotion?.type_id) ? this.language.SHEIN_KEY_PWA_27806 : this.language.SHEIN_KEY_PWA_27807
    },
    handleAttentionDrawerClose() {
      this.attentionConfig.show = false
    },
    handleGiveUp() {
      this.handleAttentionDrawerClose()
      EventClose.notify()
    },
    addBagAnimateFinish() {
      if (this.needAutoPick && !this.isShowAutoPick) { // 如果需要自动弹出或者没有弹出过
        this.handlePick(true)
        this.isShowAutoPick = true
      }

      this.needAutoPick = false
    },
    getSaExtraData() {
      const { promotion_id } = this.promotion
      return {
        promotion_code: promotion_id,
        is_satisfied: this.addOnStatus,
        coupon_change: this.couponChange,
        action: this.overFirstRange ? 'pick' : 'view',
      }
    },
    hanldeBusinessCartUpdated(info) {
      if (['updateQuantity', 'delete', 'modify', 'updateAttr'].includes(info?.action)) {
        EventSetProgressPromise.notify()
      }
      EventUpdateBusinessCart.notify(info) // 通知外层调用方
    },
    getBusinessCartFoot() {
      return this.$refs.BusinessCartFoot
    },
  },
}
</script>

<style lang="less" scoped>
.accessory {
  height: 100%;
}
</style>

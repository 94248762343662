<template>
  <div class="other-promotion-main">
    <AddOnItem
      :language="language"
      :abt-info="abtInfo"
      :drawer-config="drawerConfig"
      :query-info="{
        ...$attrs.queryInfo,
        ...anotherInfo,
      }"
      :type="$attrs.type"
      :sa-info="$attrs.saInfo"
      :config="config"
      :footer-type="footerType"
      :feeds-type="feedsType"
      :get-business-cart-foot="getBusinessCartFoot"
    >
      <template #header>
        <transition
          name="header-animate"
        >
          <AddOnHeader
            :language="language"
            :promo="promotion"
            :is-full-screen="isFullScreen"
            :type="promotion.addOnType"
          />
        </transition>
      </template>
      <template #footer="{ viewCart }">
        <BusinessCartFoot 
          v-if="isBusinessCartBottom"
          ref="BusinessCartFoot"
          type="promotion"
          scene="promotion"
          :promotion-id="promotion.promotion_id"
          :state="$attrs.saInfo && $attrs.saInfo.state"
          :tips="promotion.content && promotion.content.tip"
          :status="addOnStatus"
          :abt-type="abtType"
          :feeds-type="feedsType"
          :auto-use-coupon="autoUseCoupon"
          :auto-use-point="autoUsePoint"
          theme="promotion"
          @checkoutClick="viewCart"
          @cartUpdated="hanldeBusinessCartUpdated"
        >
          <template #progress>
            <ProgressBar
              transparent
              animation
            />
          </template>
        </BusinessCartFoot>
        <Foot
          v-else
          :language="language"
          :promotion="promotion"
          @back="viewCart"
        />
      </template>
    </AddOnItem>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { EventProgressUpdate, EventUpdateBusinessCart, EventSetProgressPromise  } from '../../utils/event.js'
import { calcPromotionProcess, getPromotionProgressStatus } from 'public/src/pages/common/addOnItem/utils/index.js'
import _ from 'lodash'
import BusinessCartFoot from 'public/src/pages/common/add-on/comps/business-cart-foot/index.vue'
const { IS_RW } = gbCommonInfo

export default {
  name: 'OtherPromotionMain',
  components: {
    AddOnItem: () => import(/* webpackChunkName: "add_on_item" */'../AddOnItem.vue'),
    Foot: () => import(/* webpackChunkName: "add_on_item" */'./Foot.vue'),
    AddOnHeader: () => import(/* webpackChunkName: "add_on_item" */'./Header.vue'),
    BusinessCartFoot,
    ProgressBar: () => import(/* webpackChunkName: "add_on_item" */'public/src/pages/common/addOnItem/comps/base/ProgressBar.vue'),
  },
  props: {
    promotion: {
      type: Object,
      default: () => ({}),
    },
    drawerConfig: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isShowBubble5: false,
      couponChange: 0,
      isShowBubble4: false,
    }
  },
  computed: {
    ...mapState(['abtInfo', 'language', 'isFullScreen', 'progressDataPromise']),
    anotherInfo() {
      const diffAmount = this.promotion?.promotion_range_info?.diff?.value_amount
      let goodsPrice = ''
      if(diffAmount) goodsPrice = ((diffAmount?.usdAmount || 0) * 100).toFixed(0)
      const { lable_id } = this.promotion
      const jsonRuleId = this.abtInfo?.addnewhotJson?.param || {}
      return {
        goodsPrice, // 凑单差额价格(美分)
        includeTspId: lable_id,
        // excludeTspId
        jsonRuleId,
        location: 'addnewhotJson',
      }
    },
    // 是否是每满的活动
    isEveryFull() {
      const { type_id, rules = [] } = this.promotion
      
      return type_id == 1 || (type_id == 15 && rules[0].type == 2) || (type_id == 14 && (rules[0].type == 2 || rules[0].type == 4))
    },
    // 是否已满足最大优惠限制（暂时只有type_id=14才有）
    isFullMaxDiscount() {
      const { type_id, rules = [] } = this.promotion
      const { max_discount } = rules?.[0] || {}
      const { hit_range } = this.promotion?.promotion_range_info || {}
      return type_id == 14 && max_discount > 0 && hit_range?.total_discount_amount?.amount >= max_discount
    },
    // 非标车
    abtType() {
      const { cartadd_promotion_bottom_style } = this.abtInfo?.CartaddPromotionBottomStyle?.param || {}
      return cartadd_promotion_bottom_style || 'A'
    },
    feedsType() {
      const { cartpop_feeds_additems } = this.abtInfo?.CartaddFeeds?.param || {}
      return cartpop_feeds_additems || 'off' // off | on_A | on_B
    },
    isBusinessCartBottom() {
      if (IS_RW) return false
      return ['A', 'B', 'C'].includes(this.abtType) && !this.config.isCloseBusinessCartEntry
    },
    footerType() {
      if (this.isBusinessCartBottom) return 'business-cart'
      return 'normal'
    },
    addOnStatus() {
      const range = this.promotion?.promotion_range_info?.range
      const { rules = [] } = this.promotion
      let is_satisfied = 0
      if(range > 0 && range < rules.length) is_satisfied = 2
      else if(range >= rules.length) is_satisfied = 1
      if(range > 0 && this.isEveryFull) {
        if (this.isFullMaxDiscount) {
          is_satisfied = 1
        } else {
          is_satisfied = 2
        }
      }
      return is_satisfied
    },
    autoUseCoupon() {
      return this.abtInfo?.Cartshowcoupon?.param?.cart_show_coupon_switch == 'on' ? '1' : '0'
    },
    autoUsePoint() {
      const [auto_use_point] = (this.abtInfo?.cartautopoints?.param?.is_auto_point || '').split('_')
      return auto_use_point == 0 ? '0' : '1'
    }
  },
  watch: {
    'promotion': {
      handler(newVal, oldVal) {
        this.reload(newVal, oldVal)
      },
      deep: true,
    },
  },
  mounted() {
    let result = this.getProgressData(this.promotion)
    EventProgressUpdate.notifySync(result)
  },
  methods: {
    /**
     * 获取进度条数据
     * @param {*} isLoop 是否循环
     */
    getProgressData(promo, isLoop) {
      if (this.isEveryFull) {
        const { promotion_range_info, rules } = promo
        const { diff } = promotion_range_info || {}
        const { value } = rules?.[0] || {}
        return [
          {
            progressRate: this.isFullMaxDiscount ? 100 : (value - diff.value) / value * 100, // 凑单进度
            ruleIndex: 0, // 当前档位
            activeRuleIndex: 0, // 正在凑单档位
            isLoop,
            isEveryFull: this.isEveryFull,
            range: promotion_range_info?.range,
            isFullMaxDiscount: this.isFullMaxDiscount, // 是否已满足最大优惠限制
          }
        ]
      }
      const { promotion_range_info, rules } = promo
      const { range, next } = promotion_range_info || {}
      if (!Array.isArray(rules)) return []
      return rules.map((rule, index) => {
        return {
          progressRate: calcPromotionProcess(rule, rules[index - 1], index, promotion_range_info), // 凑单进度
          ruleIndex: index, // 当前档位
          activeRuleIndex: next == 0 ? -1 : range, // 正在凑单档位
          range,
        }
      })
    },
    getSaExtraData() {
      return {
        coupon_change: this.couponChange,
        promotion_code: this.promotion?.promotion_id,
        is_satisfied: this.addOnStatus,
      }
    },
    hanldeBusinessCartUpdated(info) {
      if (['updateQuantity', 'delete', 'modify', 'updateAttr'].includes(info?.action)) {
        EventSetProgressPromise.notify()
      }
      EventUpdateBusinessCart.notify(info) // 通知外层调用方
    },
    reload: _.throttle(function reload(newVal, oldVal) {
      let bubbleText = ''
      let isLoop = false

      const newProgressData = this.getProgressData(newVal)
      const oldProgressData = this.getProgressData(oldVal)
      const { isRangeUp, isUp } = getPromotionProgressStatus(newProgressData, oldProgressData)

      if(isRangeUp) {
        bubbleText = !this.isShowBubble5 ? this.promotion?.addOnTips?.reachBubbleText : ''
        this.isShowBubble5 = true
        this.couponChange = 1
        isLoop = this.isEveryFull
      } else if(!this.isShowBubble4 && !this.isShowBubble5) {
        this.isShowBubble4 = true
        bubbleText = this.promotion?.addOnTips?.closeBubbleText
      }

      this.$refs.BusinessCartFoot?.refetchCarts()
      this.progressDataPromise?.resolve?.({
        progressData: this.getProgressData(this.promotion, isLoop),
        bubbleText: isUp ? bubbleText : '',
        isLoop,
      })
    }, 1000, {
      'leading': true,
      'trailing': false,
    }),
    getBusinessCartFoot() {
      return this.$refs.BusinessCartFoot
    },
  },
}
</script>
<style lang="less" scoped>
.other-promotion-main {
  height: 100%;
}
</style>
